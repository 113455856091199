(function(){
  // Create a queue, but don't obliterate an existing one!
  var analytics = window.analytics = window.analytics || [];
  // If the real analytics.js is already on the page return.
  if (analytics.initialize) return;
  // If the snippet was invoked already show an error.
  if (analytics.invoked) {
    if (window.console && console.error) {
      console.error('Segment snippet included twice.');
    }
    return;
  }
  // Invoked flag, to make sure the snippet
  // is never invoked twice.
  analytics.invoked = true;
  // A list of the methods in Analytics.js to stub.
  analytics.methods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'once',
    'off',
    'on',
    'addSourceMiddleware',
    'addIntegrationMiddleware',
    'setAnonymousId',
    'addDestinationMiddleware'
  ];
  // Define a factory to create stubs. These are placeholders
  // for methods in Analytics.js so that you never have to wait
  // for it to load to actually record data. The `method` is
  // stored as the first argument, so we can replay the data.
  analytics.factory = function(method){
    return function(){
      var args = Array.prototype.slice.call(arguments);
      args.unshift(method);
      analytics.push(args);
      return analytics;
    };
  };
  // For each of our methods, generate a queueing stub.
  for (var i = 0; i < analytics.methods.length; i++) {
    var key = analytics.methods[i];
    analytics[key] = analytics.factory(key);
  }
  // Define a method to load Analytics.js from our CDN,
  // and that will be sure to only ever load it once.
  analytics.load = function(key, options){
    // Create an async script element based on your key.
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://cdn.segment.com/analytics.js/v1/'
      + key + '/analytics.min.js';

    // if the analytics script fails to load (like it gets blocked by ad blockers), ensure that Intercom still starts.
    script.onerror = manuallyInitializeIntercom;
    // Insert our script next to the first script element.
    var first = document.getElementsByTagName('script')[0];
    first.parentNode.insertBefore(script, first);
    analytics._loadOptions = options;
  };
  analytics._writeKey = 'EqlAAWNk98ZhKqDZ8FGJ6UGTqpWJUHKt'
  // Add a version to keep track of what's in the wild.
  analytics.SNIPPET_VERSION = '4.15.3';
  // Load Analytics.js with your key, which will automatically
  // load the tools you've enabled for your account. Boosh!
  analytics.load("EqlAAWNk98ZhKqDZ8FGJ6UGTqpWJUHKt");
  // Make the first page call to load the integrations. If
  // you'd like to manually name or tag the page, edit or
  // move this call however you'd like.
  analytics.page();
})();

/** Constants **/
const APP_ID = 'q3bdiij1';
const API_BASE = 'https://api-iam.intercom.io';
const INTERCOM_BOOT = 'boot';

/* Get User Id */
const getIntercomUserId = (user) =>
  user.find(o => o.type === 'sub').value;

/* Get User Email */
const getIntercomUserEmail = (user) =>
  user.find(o => o.type === 'name').value;

/* Get Intercom User Hash */
const getIntercomUserHash = async (user) => {
  // Get user id
  const userId = getIntercomUserId(user);

  // Fetch intercom user hash
  return await fetch(`/api/user-accounts-manager/users/${userId}/intercom/user-hash`, {headers: { 'X-CSRF': '1' }});
}

async function manuallyInitializeIntercom() {
  // Intercom library initialization
  (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src=`https://widget.intercom.io/widget/${APP_ID}`;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();

  // Get user
  const userResponse = await window.getUser(),
    user = await userResponse.json();

  // Get intercom user hash
  const userHashResponse = await getIntercomUserHash(user),
    userHash = await userHashResponse.text();

  // Get user email & id
  const email = getIntercomUserEmail(user),
    user_id = getIntercomUserId(user);

  // Intercom configuration
  window.Intercom(INTERCOM_BOOT, {
    api_base: API_BASE,
    app_id: APP_ID,
    user_hash: userHash,
    user_id: user_id,
    email: email,
    created_at: Date.now(),
  });
}
